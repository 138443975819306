<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <b-col>
          <b-form-group>
              <label>Organization</label>
              <v-select
                v-model="organizationFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="organizations"
                :reduce="option => option.id"
                label="value"
                class="report-filter-select"
                placeholder="Select Organization"
              >
                <template #selected-option="{ value }">
                  <span class="text-truncate overflow-hidden">
                    {{ value }}
                  </span>
                </template>
              </v-select>
            </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
              <label>Template</label>
              <v-select
                v-model="templateFilter"
       
                :options="templates"
                :reduce="option => option.id"
                label="value"
        
                placeholder="Select Template"
              >
                <template #selected-option="{ value }">
                  <span class="text-truncate overflow-hidden">
                    {{ value }}
                  </span>
                </template>
              </v-select>
            </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
              <label>Status</label>
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                class="report-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-form-group>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refDebugListTable"
      :items="fetchDebugs"
      responsive
      :fields="tableColumns"
     
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(status)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link
          :to="{ name: 'template-to-edit', params:{ templateId: data.item.template._id }, query: {organization: data.item.organization._id, organizationName: data.item.organization.name, debugId: data.item.id}}"
          class="font-weight-bold"
        >
          #{{ data.index + 1 }}
        </b-link>
      </template>

      <!-- Column: Report Status -->
      <template #cell(status)="data">
        <b-avatar
          :id="`report-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolveDebugStatusVariantAndIcon(data.item.status).variant}`"
        >
          <feather-icon
            :icon="resolveDebugStatusVariantAndIcon(data.item.status).icon"
          />
        </b-avatar>
    
      </template>

      <!-- Column: Template -->
      <template #cell(template)="data">
        <span class="text-nowrap">
          {{ data.item.template.name }}
        </span>
      </template>

      <!-- Column: User -->
      <template #cell(user)="data">
        <span class="text-nowrap">
          {{ data.item.user.name }}
        </span>
      </template>

      <!-- Column: User -->
      <template #cell(organization)="data">
        <span class="text-nowrap">
          {{ data.item.organization.name }}
        </span>
      </template>


      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.item.createdAt | moment}}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
    

          <feather-icon
            :id="`report-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'template-to-edit', params:{templateId: data.item.template._id}, query: {organization: data.item.organization._id, organizationName: data.item.organization.name, debugId: data.item.id}})"
          />
          <b-tooltip
            title="Preview Debug logs"
            :target="`report-row-${data.item.id}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>



          </b-dropdown>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalDebugs"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BFormGroup
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useDebugsList from './useDebugList'
import moment from "moment"
import debugStoreModule from '../debugStoreModule'
import axios from '@axios'
import router from "@/router";

export default {
  data(){
    return {
      d: null,
      organizations: [],
      templates: []
    }
  },
  created(){
    let _this = this;
    axios.get(`organizations`, {params: {limit: 1000, selectedFields: "id name"}}).then((response) => {
      this.organizations = response.data.results.map((x) => {
        return {
          id: x.id,
          value: x.name,
        }
      });
      if(!_this.organizationFilter){
        _this.organizationFilter = _this.organizations[0].id;

      }

    })
  },
  watch: {
    organizationFilter(newVal){
      this.templateFilter = null;
      axios.get(`templates`, {params: {limit: 1000, organizations: newVal, selectedFields: "id name"}}).then((response) => {
          
          this.templates = response.data.templates.results.map((x) => {
            return {
              id: x.id,
              value: x.name,
            }
          });
        })
    
  
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
BFormGroup,
    vSelect,
  },
  filters: {
    moment: function (date) {
      return moment(date).format('LLL');
    }
  },
  setup() {
    const DEBUG_APP_STORE_MODULE_NAME = 'app-debug'

    // Register module
    if (!store.hasModule(DEBUG_APP_STORE_MODULE_NAME)) store.registerModule(DEBUG_APP_STORE_MODULE_NAME, debugStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEBUG_APP_STORE_MODULE_NAME)) store.unregisterModule(DEBUG_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'INIT',
      'OK',
      'ERROR'
    ]

   

    const {
      fetchDebugs,
      tableColumns,
      perPage,
      currentPage,
      totalDebugs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDebugListTable,

      statusFilter,
      organizationFilter,
      templateFilter,
      refetchData,

      resolveDebugStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useDebugsList(router.currentRoute.query)



  


    return {
      fetchDebugs,
      tableColumns,
      perPage,
      currentPage,
      totalDebugs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDebugListTable,

      statusFilter,
      organizationFilter,
      templateFilter,
      refetchData,

      statusOptions,
      avatarText,
      resolveDebugStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.report-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.report-message-class {
 width: 300px;
  max-width: 300px;
  min-width: 300px;

}
.report-message-th-class {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
}
</style>
